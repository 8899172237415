<script setup lang="ts">
import { userEntity } from '@/entities/user'

const props = defineProps({
  size: {
    type: String as () => 's' | 'm' | 'l',
    default: 's',
  },
  src: {
    type: String,
    default: '',
  },
})

const userStore = userEntity.model.useUserStore()

const avatarGenerated = computed(() => {
  const name = userStore.me?.info?.firstName
  if (!name) return ''
  return userEntity.model.avatarSrcFromName(name)
})
const avatar = computed(() => props.src || userStore.me?.info?.avatar || avatarGenerated.value || '')
</script>

<template>
  <div
    class="skeleton overflow-hidden rounded-full"
    :class="{
      'h-44 w-44': props.size === 's',
      'h-64 w-64': props.size === 'm',
      'h-80 w-80': props.size === 'l',
    }"
  >
    <img v-if="avatar" :src="avatar" alt="User avatar" class="" />
  </div>
</template>
