import { userAvatarGet } from '../api/userAvatarGet.api'

const URI_AVATAR_GENERATOR = 'https://api.dicebear.com/9.x/thumbs/svg?seed='

export async function getAvatar(name: string): Promise<string> {
  const response = await userAvatarGet(name)
  return (response.isOk && response.data?.avatar) || ''
}

export function avatarSrcFromName(name: string): string {
  return `${URI_AVATAR_GENERATOR}${name}`
}
